import React, { useState, useEffect } from "react";
import { Layout } from "layout";
import { PageProps } from "gatsby";
import { Card, Button } from "lib";
import { Divider, Form, Input, message, Select, Typography, Grid } from "antd";
import { selectProps } from "@objects";
import {
  SupportedLanguageClient,
  ClientUsersClient,
  UpdateClientUserPasswordCommand,
  UpdateClientUserCommand,
  IUpdateClientUserCommand,
  IUpdateClientUserPasswordCommand,
} from "@api";
import { showError } from "@action";
import { useIntl } from "react-intl";

const ChangePasswordPage: React.FC<PageProps> = ({ location }) => {
  interface IFormValue
    extends IUpdateClientUserCommand,
      IUpdateClientUserPasswordCommand {}

  const { state = {} }: { state: any } = location;
  const [form] = Form.useForm();
  const [options, setOptions] = useState<
    { label: string | number; value: number | string }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSetting] = useState({
    clientGroupCanCustomizePreferredLanguage: false,
    clientGroupCanCustomizeEmail: false,
    clientGroupCanCustomizePassword: false,
    clientGroupCanCustomizeFullName: false,
    clientGroupCustomizeFullNameLabel: "",
  });
  const layout = {
    labelCol: { xl: 12, sm: 12, xs: 24 },
    wrapperCol: { xl: 12, sm: 12, xs: 24 },
  };

  const { sm } = Grid.useBreakpoint();

  const { formatMessage } = useIntl();

  const submitLabel = formatMessage({ id: "ProfileSubmit" });

  const ProfileTitle = formatMessage({ id: "ProfileTitle" });

  const fullNameLabel = formatMessage({ id: "ProfileFullName" });

  const emailLabel = formatMessage({ id: "ProfileEmail" });

  const preferredLang = formatMessage({ id: "ProfilePreferredLanguage" });

  const currentPasswordLabel = formatMessage({ id: "ProfileCurrentPassword" });

  const newPasswordLabel = formatMessage({ id: "ProfileNewPassword" });

  const confirmPassword = formatMessage({ id: "ProfileConfirmPassword" });

  const confirmPasswordWarning = formatMessage({
    id: "ProfileConfirmPasswordWarning",
  });

  const requiredWarning = formatMessage({ id: "ProfileRequiredWarning" });

  const emailWarning = formatMessage({ id: "ProfileEmailTypeWarning" });

  const patternWarning = formatMessage({ id: "ProfilePatternWarning" });

  const onFinish = async (values: IFormValue) => {
    try {
      setIsLoading(true);
      const client = new ClientUsersClient();
      if (setting.clientGroupCanCustomizePassword) {
        const result = await client.changePassword(
          new UpdateClientUserPasswordCommand({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          })
        );
        if (result.errors?.length) throw result.errors;
      }

      if (
        setting.clientGroupCanCustomizePreferredLanguage ||
        setting.clientGroupCanCustomizeFullName ||
        setting.clientGroupCanCustomizeEmail
      ) {
        await client.update(
          new UpdateClientUserCommand({
            fullName: values.fullName,
            email: values.email,
            preferredLanguageId: values.preferredLanguageId,
          })
        );
      }
      window.location.replace(state?.redirectUrl ?? "/");
    } catch (err) {
      if (Array.isArray(err)) {
        message.warning(err.join(", "));
      } else {
        showError(err);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initPage = async () => {
      const langClient = new SupportedLanguageClient();
      const result = await langClient.getSupportedLanguages().catch((err) => {
        showError(err);
        return [];
      });

      const userClient = new ClientUsersClient();
      const user = await userClient.getCurrentClientUser().catch((err) => {
        showError(err);
        return null;
      });

      setSetting({
        clientGroupCanCustomizePreferredLanguage:
          !!user?.clientGroupCanCustomizePreferredLanguage,
        clientGroupCanCustomizeEmail: !!user?.clientGroupCanCustomizeEmail,
        clientGroupCanCustomizePassword:
          !!user?.clientGroupCanCustomizePassword,
        clientGroupCanCustomizeFullName:
          !!user?.clientGroupCanCustomizeFullName,
        clientGroupCustomizeFullNameLabel:
          user?.clientGroupCustomFullNameLabel ?? "",
      });
      setOptions(
        result.map((option) => ({
          label: option.displayName ?? "",
          value: option.id ?? "",
        }))
      );
      form.setFieldsValue({
        ...user,
        preferredLanguageId: user?.preferredLanguage?.id,
      });
    };

    initPage();
  }, []);

  return (
    <Layout title="WelcomeNavigationTitle" hidden>
      <Card style={{ maxWidth: "1000px", margin: "0px auto" }}>
        {sm ? (
          <Divider>
            <Typography.Title level={3}>{ProfileTitle}</Typography.Title>
          </Divider>
        ) : (
          <Typography.Title
            level={3}
            style={{ margin: "16px auto", textAlign: "center" }}
          >
            {ProfileTitle}
          </Typography.Title>
        )}
        <Form form={form} labelAlign="left" onFinish={onFinish} {...layout}>
          {setting.clientGroupCanCustomizeFullName && (
            <Form.Item
              label={
                setting.clientGroupCustomizeFullNameLabel == ""
                  ? fullNameLabel
                  : setting.clientGroupCustomizeFullNameLabel
              }
              name="fullName"
              rules={[
                { required: true, whitespace: true, message: requiredWarning },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {setting.clientGroupCanCustomizeEmail && (
            <Form.Item
              label={emailLabel}
              name="email"
              rules={[
                { required: true, whitespace: true, message: requiredWarning },
                { type: "email", message: emailWarning },
              ]}
            >
              <Input />
            </Form.Item>
          )}
          {setting.clientGroupCanCustomizePreferredLanguage && (
            <Form.Item
              label={preferredLang}
              name="preferredLanguageId"
              rules={[{ required: true, message: requiredWarning }]}
            >
              <Select {...selectProps} options={options} />
            </Form.Item>
          )}
          {setting.clientGroupCanCustomizePassword && (
            <>
              <Form.Item
                label={currentPasswordLabel}
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: requiredWarning,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={newPasswordLabel}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: requiredWarning,
                  },
                  {
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@#$!%*?&-]{6,}$/,
                    message: patternWarning,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={confirmPassword}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: requiredWarning,
                  },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value || getFieldValue("newPassword") === value)
                        return Promise.resolve();

                      return Promise.reject(confirmPasswordWarning);
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}

          <Form.Item
            wrapperCol={{ sm: 24 }}
            style={{ justifyContent: "center", marginTop: "24px" }}
          >
            <Button block htmlType="submit" loading={isLoading}>
              {submitLabel}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  );
};

export default ChangePasswordPage;
